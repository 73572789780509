import process from 'process';
import allConstants from '@utils/constants.json';

export type Basename = 'cds-wissen' | 'cds-wissen-gpp-de' | 'cds-wissen-gpp-en';
export type DeploymentStage = 'dev' | 'test' | 'prod';

type SiteConstants = (typeof allConstants)[Basename];

export type Metadata = {
  basename: Basename;
  constants: SiteConstants;
  isGpp: boolean;
  isGppDE: boolean;
  searchUrl: string;
  deploymentStage: DeploymentStage;
};

export const basename = process.env.NEXT_PUBLIC_WEBAPP_BASENAME as Basename;
export const isGpp = basename.includes('gpp');
export const isGppDE = basename.includes('gpp-de');
