// @Note regenerator-runtime must come first!
import 'regenerator-runtime/runtime';

import { globalStyles } from '@neui/styleguide-commerzbank';
import { FlagsProvider } from 'flagged';
import { globalCss } from '@neui/core';
import { useEffect, type ReactElement, type ReactNode } from 'react';
import { baseTheme } from 'styling/stitches.config';
import { EnvironmentProps, toBool } from '@utils/env';
import { TrackerContextProvider } from '@utils/snowplowTracking';
import { makeServer } from '@utils/mirage';

import type { AppProps } from 'next/app';
import type { NextPage } from 'next';

if (toBool(process.env.NEXT_PUBLIC_ENABLE_MOCK_CHATBOT)) {
  makeServer({ environment: 'development' });
}

export type NextPageWithLayout<P> = NextPage<P> & {
  getLayout?: (page: ReactElement, props: P) => ReactNode;
};

type AppPropsWithLayout = AppProps<EnvironmentProps> & {
  Component: NextPageWithLayout<any>;
};

const customGlobalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
  },
  h1: {
    margin: '0 !important',
  },
});

export default function MyApp({
  Component,
  pageProps,
}: AppPropsWithLayout): React.ReactNode {
  globalStyles();
  customGlobalStyles();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  // Initialize GA4 dataLayer
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);

  return (
    <FlagsProvider features={pageProps.features}>
      <TrackerContextProvider>
        {getLayout(
          <div className={`${baseTheme}`}>
            <Component {...pageProps} />
          </div>,
          pageProps,
        )}
      </TrackerContextProvider>
    </FlagsProvider>
  );
}
