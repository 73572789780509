import {
  PostInitiateTextchatResponse,
  PostMessagesResponse,
  PostSendMessageResponse,
  PostStartResponse,
  SendMessageRequest,
  //   TypingStateRequest,
} from '@cds/chatbot-client';
import { createServer, Model, Response } from 'miragejs';

export function makeServer({ environment = 'test' } = {}) {
  let server = createServer({
    environment,

    models: {
      message: Model,
    },

    seeds(server) {
      const timestamp = new Date().toISOString();
      server.db.loadData({
        messages: [
          {
            displayName: 'Customer',
            participantId: 'aabdf54d-e863-4ec9-a7e8-fd63e383897b',
            participantType: 'WebUser',
            sequenceNumber: 0,
            state: 2,
            // timestamp: '2024-09-13T08:23:56.047103Z',
            timestamp,
            __type: 'urn:inin.com:webChat:chatParticipantStateChangedMessage',
          },
          {
            contentType: 'text/plain',
            participantId: '00000000-0000-0000-0000-000000000000',
            sequenceNumber: 0,
            // timestamp: '2024-09-13T08:23:56.07834967',
            timestamp,
            value: 'Herzlich Willkommen bei der Commerzbank.',
            __type: 'urn:inin.com:webChat:chatTextMessage',
          },
          {
            contentType: 'text/plain',
            participantId: '00000000-0000-0000-0000-000000000000',
            sequenceNumber: 0,
            timestamp,
            value: 'Ihr Kontakt wurde aufgebaut',
            __type: 'urn:inin.com:webChat:chatTextMessage',
          },
          {
            displayName: 'Bot#Commerzbank ChatBot',
            participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
            participantType: 'Agent',
            sequenceNumber: 2,
            state: 2,
            timestamp,
            __type: 'urn:inin.com:webChat:chatParticipantStateChangedMessage',
          },
          {
            displayName: 'Bot#Commerzbank ChatBot',
            participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
            participantType: 'Agent',
            sequenceNumber: 3,
            state: 2,
            timestamp,
            __type: 'urn:inin.com:webChat:chatParticipantStateChangedMessage',
          },
          {
            contentType: 'text/plain',
            participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
            sequenceNumber: 0,
            timestamp,
            value: 'Hallo!',
            __type: 'urn:inin.com:webChat:chatTextMessage',
          },
          {
            contentType: 'text/plain',
            participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
            sequenceNumber: 0,
            timestamp,
            value:
              'Du kannst mir Nachrichten schreiben, die ich dir dann direkt zurücksende.',
            __type: 'urn:inin.com:webChat:chatTextMessage',
          },
        ],
      });
    },

    routes() {
      this.post('/chat/web/v1/initiate', () => {
        const responseBody: PostInitiateTextchatResponse = {
          result: {
            data: {
              mobileAppAvailability: {
                workgroupName: 'MOCK_WORKGROUP',
              },
            },
          },
        };
        return new Response(200, {}, responseBody);
      });

      this.post('/chat/web/v1/start', () => {
        const responseBody: PostStartResponse = {
          result: {
            data: {
              chatId: '0123456789',
              participantId: '42',
              pollWaitSuggestion: 2000,
            },
          },
        };
        return new Response(200, {}, responseBody);
      });

      this.post('/chat/web/v1/typing-state', () => {
        // const json = JSON.parse(request.requestBody) as TypingStateRequest;
        return new Response(200, {}, {});
      });

      this.post('/chat/web/v1/send-message', (schema, request) => {
        const json = JSON.parse(request.requestBody) as SendMessageRequest;
        const timestamp = new Date().toISOString();

        const processedMsg = {
          contentType: 'text/plain',
          participantId: '42',
          sequenceNumber: 0,
          timestamp,
          value: json.message,
          __type: 'urn:inin.com:webChat:chatTextMessage',
        };

        const reply = {
          contentType: 'text/plain',
          participantId: '7380260a-c635-4b67-ac76-8df233417a5a',
          sequenceNumber: 1,
          timestamp,
          value: `Antwort: ${json.message}`,
          __type: 'urn:inin.com:webChat:chatTextMessage',
        };

        schema.db.messages.insert(processedMsg);
        schema.db.messages.insert(reply);

        const responseBody: PostSendMessageResponse = {};

        return new Response(200, {}, responseBody);
      });

      this.post('/chat/web/v1/messages', (schema) => {
        const messages = schema.db.messages.where({});
        schema.db.messages.remove();

        const responseBody: PostMessagesResponse = {
          result: {
            data: {
              messages,
              pollWaitSuggestion: 2000,
            },
          },
        };

        return new Response(200, {}, responseBody);
      });

      this.passthrough('/_next/**');
      this.passthrough('https://sds.commerzbank.de/**');
    },
  });

  return server;
}
